import React, { useState } from "react";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, Button, Tooltip } from "antd";
// custom components
import { FormattedValue, WhatsAppRedirect } from "components";
import ShareQuotationModal from "../../../containers/Orders/Orders/ShareQuotationModal";
// redux
import { useSelector, Provider } from "react-redux";
// config
import config from "config";
// custom styles
import "./styles.less";
// new redux state
import store from "../../../store";
// constants
import { orderProductTypes } from "../../../constants";
//Configs
import Config from "../Config";
// hooks
import { useDownloadPDF } from "../../../hooks/useDownloadPDF";
// helpers
import { formatMaskInput } from "../../../helpers";
// icons
import truckIcon from "../../../assets/images/truck-icon.svg";
import downloadIcon from "../../../assets/images/download-icon.svg";
import mailIcon from "../../../assets/images/mail-icon.svg";
import whatsappIcon from "../../../assets/images/whatsapp-icon.svg";

const { typeEntriesColors, orderTypeTranslation } = Config;

const OrderResume = ({ id, data }) => {
  const { t: translation } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { handleDownloadPDF } = useDownloadPDF();

  const expressErrorsData = useSelector(
    (store) => store.crm.order.expressErrors
  );

  const getValue = (source, property) => {
    return source ? source[property] : null;
  };

  const orderTypeColor = typeEntriesColors[getValue(data, "type")];

  const formPathByProduct = {
    CARGO_OPEN_POLICY: "seguro-de-carga/multiplo",
    CARGO_SINGLE_BOARDING: "seguro-de-carga/unico",
    SURETY: "seguro-garantia",
    LIFE: "seguro-de-vida",
  };

  const customerWhatsappURL = `whatsapp://send?phone=55${formatMaskInput(
    getValue(getValue(data, "initial"), "phoneNumber")
  )}`;

  const formUrl = data
    ? `${config.formsUrl}/${formPathByProduct[data.type]}/dados-iniciais/${
        data.code
      }`
    : "";

  const suretyQuotationUrl = data
    ? `${config.apiUrl}/report/v1/surety/quotation/${data.code}`
    : "";

  const openPolicyQuotationUrl = data
    ? `${config.apiUrl}/report/v1/open-policy/quotation/${data.code}`
    : "";

  const cargoQARurl = data ? `${config.apiUrl}/report/v1/${data.code}` : "";

  const getProductLabel = () => {
    return orderTypeTranslation[getValue(data, "type")];
  };

  const getEmissionLabel = () => {
    const policyData = getValue(data, "policy");

    if (!policyData?.origin)
      return translation("order.resume.manualEmissionLabel");

    return policyData?.origin === "CLIENT"
      ? translation("order.resume.emissionOrigin.client")
      : translation("order.resume.emissionOrigin.api");
  };

  const ResumeDataItem = ({ children, label }) => {
    return (
      <div className="resumeDataItem">
        <h5 className="label">{label}</h5>
        <div className="text">{children}</div>
      </div>
    );
  };

  return data ? (
    <div className="resumeSection">
      {visible && (
        <Provider store={store}>
          <ShareQuotationModal
            code={data.code}
            visible={visible}
            onCancel={() => setVisible(false)}
          />
        </Provider>
      )}
      <Row type="flex" justify="space-between" align="middle">
        <div
          className="resumeHeader"
          style={{ backgroundColor: `${orderTypeColor}` }}
        >
          <div className="orderInfoWrapper">
            <p>{getProductLabel()}</p>
            {data && "hasExpressEmission" in data && (
              <span className="emissionTypeBadge">{getEmissionLabel()}</span>
            )}
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="formLink"
            href={formUrl}
          >
            {getValue(data, "code")}
          </a>
        </div>
      </Row>
      <Row type="flex" align="top">
        <Col sm={24} md={8} className="gx-align-self-start">
          <ResumeDataItem label={translation("order.resume.companyNameLabel")}>
            {getValue(getValue(data, "initial"), "companyName")}
          </ResumeDataItem>
          <ResumeDataItem label={translation("order.resume.contactNameLabel")}>
            {getValue(getValue(data, "initial"), "name")}
          </ResumeDataItem>
          <ResumeDataItem label={translation("order.resume.email")}>
            {getValue(getValue(data, "initial"), "email")}
          </ResumeDataItem>
        </Col>

        <Col sm={24} md={8} className="gx-align-self-start">
          <ResumeDataItem
            label={translation("order.resume.documentNumberLabel")}
          >
            <FormattedValue
              mask="_"
              displayType="text"
              format={getValue(getValue(data, "initial"), "contactType")}
              value={getValue(getValue(data, "initial"), "documentNumber")}
            />
          </ResumeDataItem>
          <ResumeDataItem label={translation("order.resume.phoneNumberLabel")}>
            <WhatsAppRedirect
              value={getValue(getValue(data, "initial"), "phoneNumber")}
              canReceive={getValue(
                getValue(data, "initial"),
                "receiveWhatsAppMessage"
              )}
            >
              <FormattedValue
                mask="_"
                displayType="text"
                format="contactPhone"
                value={getValue(getValue(data, "initial"), "phoneNumber")}
              />
            </WhatsAppRedirect>
          </ResumeDataItem>

          <ResumeDataItem
            label={translation("order.resume.salesRepresentativeLabel")}
          >
            {getValue(getValue(data, "salesRepresentative"), "name")}
          </ResumeDataItem>
        </Col>
        <Col sm={24} md={8}></Col>
      </Row>
      {getValue(data, "type") === orderProductTypes.CARGO_OPEN_POLICY && (
        <Row type="flex" className="gx-mt-1">
          <Col sm={24} md={8} className="gx-align-self-start">
            <ResumeDataItem
              label={translation("order.resume.monthlyShipmentQuantityLabel")}
            >
              {getValue(getValue(data, "general"), "monthlyShipmentQuantity")}
            </ResumeDataItem>
          </Col>
          <Col sm={24} md={8} className="gx-align-self-start">
            <ResumeDataItem
              label={translation("order.resume.maximumAmountPerBoardingLabel")}
            >
              <FormattedValue
                type="currency"
                value={getValue(
                  getValue(data, "general"),
                  "maximumAmountPerBoarding"
                )}
              />
            </ResumeDataItem>
          </Col>
        </Row>
      )}
      <div className="orderResumeActions">
        {(getValue(data, "type") === orderProductTypes.CARGO_OPEN_POLICY ||
          getValue(data, "type") ===
            orderProductTypes.CARGO_SINGLE_BOARDING) && (
          <>
            <Tooltip
              placement="topLeft"
              title={translation("order.resume.downloadQAR")}
            >
              <Button
                type="primary"
                shape="circle"
                className="orderResumeButton"
                onClick={() => handleDownloadPDF(id, cargoQARurl)}
              >
                <img
                  src={truckIcon}
                  alt={translation("order.resume.downloadQAR")}
                />
              </Button>
            </Tooltip>
            <Tooltip
              placement="topLeft"
              title={translation("order.resume.downloadPDF")}
            >
              <Button
                type="primary"
                shape="circle"
                className="orderResumeButton"
                onClick={() => handleDownloadPDF(id, openPolicyQuotationUrl)}
              >
                <img
                  src={downloadIcon}
                  alt={translation("order.resume.downloadPDF")}
                />
              </Button>
            </Tooltip>
            <Tooltip
              placement="topLeft"
              title={translation("order.resume.sendQuotationByMail")}
            >
              <Button
                type="primary"
                shape="circle"
                className="orderResumeButton"
                onClick={() => setVisible(true)}
              >
                <img
                  src={mailIcon}
                  alt={translation("order.resume.sendQuotationByMail")}
                />
              </Button>
            </Tooltip>
          </>
        )}
        {getValue(data, "type") === orderProductTypes.SURETY && (
          <Tooltip
            placement="topLeft"
            title={translation("order.resume.downloadPDF")}
          >
            <Button
              type="primary"
              shape="circle"
              className="orderResumeButton"
              onClick={() => handleDownloadPDF(id, suretyQuotationUrl)}
            >
              <img
                src={downloadIcon}
                alt={translation("order.resume.downloadPDF")}
              />
            </Button>
          </Tooltip>
        )}
        {getValue(getValue(data, "initial"), "receiveWhatsAppMessage") && (
          <Tooltip
            placement="topLeft"
            title={translation("order.resume.sendWhatsapp")}
          >
            <a
              href={customerWhatsappURL}
              className="orderResumeButton"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                type="primary"
                shape="circle"
                className="orderResumeButton"
              >
                <img
                  src={whatsappIcon}
                  alt={translation("order.resume.sendWhatsapp")}
                />
              </Button>
            </a>
          </Tooltip>
        )}
      </div>
      {expressErrorsData && expressErrorsData.length > 0 && (
        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <ResumeDataItem label={translation("order.resume.expressError")}>
              {expressErrorsData.map((error) => {
                return <span className="expressError-tag">{error}</span>;
              })}
            </ResumeDataItem>
          </Col>
        </Row>
      )}
    </div>
  ) : null;
};

export default OrderResume;
