import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import axios from "../util/axios";

export function useDownloadPDF() {
  const [loader, setLoader] = useState(false);
  const { t: translation } = useTranslation();

  async function handleDownloadPDF(orderId, pdfURL) {
    setLoader(true);
    const todayDate = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
    await axios
      .get(`${pdfURL}`, { responseType: "arraybuffer" })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${orderId}_${todayDate}.pdf`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        message.error(translation("printReport.downloadErrorMessage"));
      });
  }

  return { handleDownloadPDF, loader };
}
