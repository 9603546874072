//Helpers
import {
  formatMaskInput,
  formatPhone,
  getFormattedDate,
  formatAmountValue,
} from "helpers";
// Wrapper config
import config from "components/Order/OrderContentWrapper/config";

// const nestedTabKeys = ["legalOpinion", "complainant"];

function formatValue(value) {
  if (!value) return null;
  else return value;
}

function formatSuretyConfig(data, configurations, externalChanges = {}) {
  const jsonTerm = data.bond
    ? data.bond.jsonTerm
    : configurations.bond.jsonTerm;
  // const bondType = data.bond ? data.bond.type : configurations.bond.type;

  const allowedTabs = [];

  for (let tabKey in config) {
    if (config.hasOwnProperty(tabKey)) {
      const tabEntry = config[tabKey];
      //If no allowed entity exist than tabKey is qualified for save
      if (tabEntry.hasOwnProperty("allowed")) {
        //Check the allowed jsonTerm array contains jsonTerm
        if (tabEntry.allowed.includes(jsonTerm)) allowedTabs.push(tabKey);
      } else {
        allowedTabs.push(tabKey);
      }
    }
  }

  const saveFormatters = {
    initial() {
      const changes = configurations.initial;
      return {
        name: changes.name,
        email: changes.email,
        phoneNumber: formatPhone(changes.phoneNumber),
        phoneType: changes.phoneType,
        receiveWhatsAppMessage: changes.receiveWhatsAppMessage,
        contactType: changes.contactType,
        documentNumber: formatMaskInput(changes.documentNumber),
        hasDocumentNumber: Boolean(changes.documentNumber),
      };
    },
    bond() {
      const changes = configurations.bond;
      return {
        type: changes.type,
      };
    },
    term() {
      const changes = configurations.term;
      const formattedData = {
        type: changes.type,
        suretyAmount: formatAmountValue(changes.suretyAmount),
        daysBetweenTerm: formatValue(changes.daysBetweenTerm),
        finalTermAt: getFormattedDate(changes.finalTermAt, "YYYY-MM-DD"),
        initialTermAt: getFormattedDate(changes.initialTermAt, "YYYY-MM-DD"),
      };
      switch (changes.type) {
        case "LABOUR_APPEAL_FILLING_BOND":
          formattedData.processAmount = formatAmountValue(
            changes.processAmount
          );
          formattedData.depositAppeal = changes.depositAppeal;
          formattedData.appealType = changes.appealType;
          break;
        case "CIVIL_COURT_BOND":
        case "LABOUR_SURETY":
        case "TAX_CREDITS_BOND":
        case "TAX_ENFORCEMENT_BOND":
        case "COURT_OTHERS_BOND":
          formattedData.appealType = changes.appealType;
          formattedData.processAmount = formatAmountValue(
            changes.processAmount
          );
          break;
        case "GENERAL_BOND":
          formattedData.contractType = changes.contractType;
          formattedData.contractAmount = changes.contractAmount
            ? formatAmountValue(changes.contractAmount)
            : null;
          formattedData.suretyPercentage = changes.suretyPercentage
            ? Number(changes.suretyPercentage)
            : null;
          formattedData.labourCoverage = changes.labourCoverage;
          formattedData.penaltyCoverage = changes.penaltyCoverage;
          break;
        case "SUPPLY_BOND":
          formattedData.contractType = changes.contractType;
          formattedData.contractAmount = changes.contractAmount
            ? formatAmountValue(changes.contractAmount)
            : null;
          formattedData.suretyPercentage = changes.suretyPercentage
            ? Number(changes.suretyPercentage)
            : null;
          formattedData.labourCoverage = changes.labourCoverage;
          formattedData.penaltyCoverage = changes.penaltyCoverage;
          formattedData.groupLifeInsurance = changes.groupLifeInsurance;
          break;
        case "BIDDING_BOND":
          formattedData.contractType = changes.contractType;
          formattedData.contractAmount = changes.contractAmount
            ? formatAmountValue(changes.contractAmount)
            : null;
          formattedData.suretyPercentage = changes.suretyPercentage
            ? Number(changes.suretyPercentage)
            : null;
          break;
        case "ADVANCED_PAYMENT_BOND":
          formattedData.penaltyCoverage = changes.penaltyCoverage;
          formattedData.contractType = changes.contractType;
          formattedData.contractAmount = changes.contractAmount
            ? formatAmountValue(changes.contractAmount)
            : null;
          formattedData.suretyPercentage = changes.suretyPercentage
            ? Number(changes.suretyPercentage)
            : null;
          break;
        case "BUSINESS_SERVICES_BOND":
          formattedData.contractName = changes.contractName;
          formattedData.processNumber = changes.processNumber
            ? changes.processNumber
            : null;
          formattedData.contractType = changes.contractType;
          formattedData.contractAmount = changes.contractAmount
            ? formatAmountValue(changes.contractAmount)
            : null;
          formattedData.suretyPercentage = changes.suretyPercentage
            ? Number(changes.suretyPercentage)
            : null;
          formattedData.labourCoverage = changes.labourCoverage;
          formattedData.penaltyCoverage = changes.penaltyCoverage;

          formattedData.liabilityInsurance = changes.liabilityInsurance;
          formattedData.groupLifeInsurance = changes.groupLifeInsurance;
          break;
        case "CONSTRUCTION_BOND":
          formattedData.contractType = changes.contractType;
          formattedData.contractAmount = changes.contractAmount
            ? formatAmountValue(changes.contractAmount)
            : null;
          formattedData.suretyPercentage = changes.suretyPercentage
            ? Number(changes.suretyPercentage)
            : null;
          formattedData.labourCoverage = changes.labourCoverage;
          formattedData.penaltyCoverage = changes.penaltyCoverage;

          formattedData.liabilityInsurance = changes.liabilityInsurance;
          formattedData.engineeringRiskInsurance =
            changes.engineeringRiskInsurance;
          formattedData.groupLifeInsurance = changes.groupLifeInsurance;
          break;
        case "MAINTENANCE_BOND":
        case "CORRECTIVE_MAINTENANCE_BOND":
        case "CUSTOMS_BOND":
        case "DEALER_PERFORMER_BOND":
        case "ENERGY_SECTOR_BOND":
        case "FINANCIAL_BOND":
        case "NAVY_SECTOR_BOND":
        case "OIL_GAS_SECTOR_BOND":
        case "REAL_ESTATE_BOND":
        case "WORK_COMPLETION_BOND":
        case "ENVIRONMENTAL_BOND":
        case "PAYMENT_RETENTION_BOND":
        case "TAX_ADMINISTRATIVE_INSTALLMENT_BOND":
          formattedData.contractType = changes.contractType;
          formattedData.contractAmount = changes.contractAmount
            ? formatAmountValue(changes.contractAmount)
            : null;
          formattedData.suretyPercentage = changes.suretyPercentage
            ? Number(changes.suretyPercentage)
            : null;
          formattedData.groupLifeInsurance = changes.groupLifeInsurance;
          break;
        default:
          break;
      }
      return formattedData;
    },
    quotations() {
      const changes = configurations.quotations;
      const formattedData = { insurer: null, premium: null };
      changes.forEach((quote) => {
        if (quote.accepted) {
          formattedData.insurer = quote.insurer;
          formattedData.premium = quote.premium;
        }
      });
      return formattedData;
    },
    obligee() {
      const changes = configurations.obligee;
      return {
        documentNumber: formatMaskInput(changes.documentNumber),
        type: changes.type,
        name: changes.name,
        zipCode: changes.zipCode,
        neighborhood: changes.neighborhood,
        address: changes.address,
        number: changes.number,
        state: changes.state,
        city: changes.city,
        additionalData: changes.additionalData,
      };
    },
    detail() {
      const changes = configurations.detail;
      const contractType = configurations?.term?.contractType;
      const formattedData = {
        type: configurations.term.type,
        additionalData: changes.additionalData,
        proposalAccepted: changes.proposalAccepted,
        receiveDraftContract: changes.receiveDraftContract,
      };

      const defineProcessNumber = () => {
        if (contractType === "PRIVATE_CONTRACT") return "PRIVATE_CONTRACT";
        return changes.processNumber || null;
      };

      const defineDetailsData = () => {
        formattedData.contractName = changes.contractName;
        formattedData.contractDate = changes.contractDate
          ? getFormattedDate(changes.contractDate, "YYYY-MM-DD")
          : null;
        formattedData.contractNumber = changes.contractNumber;
        formattedData.obligation = changes.obligation;
        formattedData.processNumber = defineProcessNumber();
      };

      switch (configurations.term.type) {
        case "LABOUR_APPEAL_FILLING_BOND":
          formattedData.tribunal = changes.tribunal;
          formattedData.court = changes.court;
          formattedData.processNumber = formatMaskInput(changes.processNumber);
          break;
        case "CIVIL_COURT_BOND":
        case "LABOUR_SURETY":
        case "TAX_CREDITS_BOND":
        case "TAX_ENFORCEMENT_BOND":
        case "COURT_OTHERS_BOND":
          formattedData.tribunal = changes.tribunal;
          formattedData.court = changes.court;
          formattedData.processNumber = formatMaskInput(changes.processNumber);
          formattedData.actionType = changes.actionType;
          break;
        case "BUSINESS_SERVICES_BOND":
        case "SUPPLY_BOND":
        case "DEALER_PERFORMER_BOND":
        case "WORK_COMPLETION_BOND":
        case "OIL_GAS_SECTOR_BOND":
        case "TAX_ADMINISTRATIVE_INSTALLMENT_BOND":
        case "NAVY_SECTOR_BOND":
        case "CORRECTIVE_MAINTENANCE_BOND":
        case "FINANCIAL_BOND":
        case "ENVIRONMENTAL_BOND":
        case "REAL_ESTATE_BOND":
        case "CUSTOMS_BOND":
        case "MAINTENANCE_BOND":
        case "ENERGY_SECTOR_BOND":
        case "CONSTRUCTION_BOND":
        case "GENERAL_BOND":
          defineDetailsData();
          break;
        case "ADVANCED_PAYMENT_BOND":
          formattedData.contractNumber = changes.contractNumber;
          formattedData.obligation = changes.obligation;
          formattedData.installment = changes.installment;
          break;
        case "BIDDING_BOND":
          formattedData.obligation = changes.obligation;
          formattedData.bidName = changes.bidName;
          formattedData.bidNumber = changes.bidNumber;
          formattedData.processNumber = defineProcessNumber();
          formattedData.bidDate = changes.bidDate
            ? getFormattedDate(changes.bidDate, "YYYY-MM-DD")
            : null;
          break;
        case "PAYMENT_RETENTION_BOND":
          formattedData.contractNumber = changes.contractNumber;
          formattedData.contractName = changes.contractName;
          formattedData.obligation = changes.obligation;
          formattedData.processNumber = defineProcessNumber();
          formattedData.installment = changes.installment;
          break;
        default:
          break;
      }
      return formattedData;
    },
    payment() {
      const changes = configurations.payments;
      return {
        installment: changes.installment,
      };
    },
  };

  return {
    initial: saveFormatters.initial(),
    bond: saveFormatters.bond(),
    quotations: saveFormatters.quotations(),
    term: saveFormatters.term(),
    obligee: saveFormatters.obligee(),
    detail: saveFormatters.detail(),
    payment: saveFormatters.payment(),
  };
}

function formatLifeConfig(data, configurations, externalChanges = {}) {
  const saveFormatters = {
    initial() {
      const changes = configurations.initial;
      return {
        name: changes.name,
        email: changes.email,
        phoneNumber: formatPhone(changes.phoneNumber),
        phoneType: changes.phoneType,
        receiveWhatsAppMessage: changes.receiveWhatsAppMessage,
        contactType: changes.contactType,
        documentNumber: formatMaskInput(changes.documentNumber),
        hasDocumentNumber: Boolean(changes.documentNumber),
      };
    },
    customer() {
      const changes = configurations.customer;
      return {
        documentNumber: formatMaskInput(changes.documentNumber),
        type: changes.type,
        name: changes.name,
        zipCode: changes.zipCode,
        neighborhood: changes.neighborhood,
        address: changes.address,
        number: changes.number,
        state: changes.state,
        city: changes.city,
        additionalData: changes.additionalData,
      };
    },
    coverages() {
      const changes = configurations.coverages;
      return {
        coverages: changes.coverages,
        assistances: changes.assistances,
      };
    },
    general() {
      const changes = configurations.general;
      return {
        employeesAmount: changes.employeesAmount,
        averageAgeEmployees: changes.averageAgeEmployees,
        capitalInsuredPerson: changes.capitalInsuredPerson,
        reasonTakeInsurance: changes.reasonTakeInsurance,
        hasBiddingWaiver: changes.hasBiddingWaiver,
        isPublicCompany: changes.isPublicCompany,
      };
    },
    details() {
      const changes = configurations.details;
      return {
        capitalType: changes.capitalType,
        insurableGroups: changes.insurableGroups,
      };
    },
    insured() {
      const changes = configurations.insured;
      return {
        insuredLives: changes.insuredLives,
        additionalData: changes.additionalData,
      };
    },
  };

  return {
    initial: saveFormatters.initial(),
    customer: saveFormatters.customer(),
    coverages: saveFormatters.coverages(),
    general: saveFormatters.general(),
    details: saveFormatters.details(),
    insured: saveFormatters.insured(),
  };
}

function formatSingleBoardingConfig(
  data,
  configurations,
  externalChanges = {}
) {
  const saveFormatters = {
    initial() {
      const changes = configurations.initial;
      return {
        name: changes.name,
        email: changes.email,
        phoneNumber: formatPhone(changes.phoneNumber),
        phoneType: changes.phoneType,
        receiveWhatsAppMessage: changes.receiveWhatsAppMessage,
        contactType: changes.contactType,
        documentNumber: formatMaskInput(changes.documentNumber),
        hasDocumentNumber: Boolean(changes.documentNumber),
      };
    },
    insured() {
      const changes = configurations.insured;
      return {
        documentNumber: formatMaskInput(changes.documentNumber),
        type: changes.type,
        name: changes.name,
        zipCode: changes.zipCode,
        neighborhood: changes.neighborhood,
        address: changes.address,
        number: changes.number,
        state: changes.state,
        city: changes.city,
        additionalData: changes.additionalData,
      };
    },
    goods() {
      const previousChanges = configurations.goods;
      const updatedChanges = externalChanges.goods;
      return {
        goods: externalChanges.hasOwnProperty("goods")
          ? updatedChanges
          : previousChanges,
      };
    },
    general() {
      const changes = configurations.general;
      return {
        companyActivity: changes.companyActivity,
        origin: changes.origin,
        destination: changes.destination,
        currency: changes.currency,
        cargoTotalAmount: formatAmountValue(changes.cargoTotalAmount),
        travelAverageAmount: changes.travelAverageAmount,
        anyUsedMerchandise: changes.anyUsedMerchandise,
      };
    },
    details() {
      const changes = configurations.details;
      return {
        modals: changes.modals,
        incoTerm: changes.incoTerm,
        boardingDate: changes.boardingDate,
        acceptRiskManagement: changes.acceptRiskManagement,
        acceptPolicyAndPrivacy: changes.acceptPolicyAndPrivacy,
      };
    },
    files() {
      const changes = configurations.files;
      return {
        files: changes.files,
      };
    },
  };

  return {
    initial: saveFormatters.initial(),
    insured: saveFormatters.insured(),
    goods: saveFormatters.goods(),
    general: saveFormatters.general(),
    details: saveFormatters.details(),
  };
}

function formatOpenPolicyConfig(data, configurations, externalChanges = {}) {
  const saveFormatters = {
    initial() {
      const changes = configurations.initial;
      return {
        name: changes.name,
        email: changes.email,
        phoneNumber: formatPhone(changes.phoneNumber),
        phoneType: changes.phoneType,
        receiveWhatsAppMessage: changes.receiveWhatsAppMessage,
        contactType: changes.contactType,
        documentNumber: formatMaskInput(changes.documentNumber),
        hasDocumentNumber: Boolean(changes.documentNumber),
      };
    },
    insured() {
      const changes = configurations.insured;
      return {
        documentNumber: formatMaskInput(changes.documentNumber),
        type: changes.type,
        name: changes.name,
        zipCode: changes.zipCode,
        neighborhood: changes.neighborhood,
        address: changes.address,
        number: changes.number,
        state: changes.state,
        city: changes.city,
        additionalData: changes.additionalData,
      };
    },
    goods() {
      const previousChanges = configurations.goods;
      const updatedChanges = externalChanges.goods;
      return {
        goods: externalChanges.hasOwnProperty("goods")
          ? updatedChanges
          : previousChanges,
      };
    },
    general() {
      const changes = configurations.general;
      return {
        modals: changes.modals,
        companyActivity: changes.companyActivity,
        monthlyShipmentQuantity: changes.monthlyShipmentQuantity,
        averageAmountPerBoarding: formatAmountValue(
          changes.averageAmountPerBoarding
        ),
        maximumAmountPerBoarding: formatAmountValue(
          changes.maximumAmountPerBoarding
        ),
        reason: changes.reason,
        startsAt: changes.startsAt,
        fleetSize: Number(changes.fleetSize),
      };
    },
    generalV2() {
      const changes = configurations.generalV2;
      return {
        modals: changes.modals,
        companyActivity: changes.companyActivity,
        monthlyShipmentQuantity: changes.monthlyShipmentQuantity,
        averageAmountPerBoarding: formatAmountValue(
          changes.averageAmountPerBoarding
        ),
        maximumAmountPerBoarding: formatAmountValue(
          changes.maximumAmountPerBoarding
        ),
        reason: changes.reason || null,
        startsAt: changes.startsAt,
        fleetSize: Number(changes.fleetSize),
      };
    },
    routes() {
      const modals = configurations.general.modals;
      const changes = configurations.routes;
      const previousChanges = changes.routes;
      const updatedChanges = externalChanges.routes;
      const routes = externalChanges.hasOwnProperty("routes")
        ? updatedChanges
        : previousChanges;
      let result = [];

      if (modals.length === 1) {
        routes.map((route) => {
          return result.push(Object.assign({}, route, { modal: modals[0] }));
        });
      } else {
        result = routes;
      }

      return {
        routes: result,
      };
    },
    coverage() {
      const changes = configurations.coverage;
      return {
        theft: changes.theft,
        other: changes.other,
        damage: changes.damage,
        accident: changes.accident,
        lifeInsurance: changes.lifeInsurance,
        trackCleaning: changes.trackCleaning,
        environmentalLiabilityRisk: changes.environmentalLiabilityRisk,
        otherDescription: changes.otherDescription,
        freight: changes.freight,
        loadingUnloading: changes.loadingUnloading,
        loadingUnloadingLifting: changes.loadingUnloadingLifting,
        fluvialRoute: changes.fluvialRoute,
      };
    },
    historic() {
      const changes = configurations.historic;
      const previousChanges = changes.accidents;
      const updatedChanges = externalChanges.accidents;
      return {
        accidents: externalChanges.hasOwnProperty("accidents")
          ? updatedChanges
          : previousChanges,
        minimumPremium: formatAmountValue(changes.minimumPremium),
        hasPreviousInsurance: changes.hasPreviousInsurance,
        previousInsurer: changes.previousInsurer,
        contractedCoverages: changes.contractedCoverages,
        maximumGuaranteeLimit: formatAmountValue(changes.maximumGuaranteeLimit),
        monthlyInvoiceAmount: formatAmountValue(changes.monthlyInvoiceAmount),
        rateOrDiscountApplied: changes.rateOrDiscountApplied,
        additionalInformation: changes.additionalInformation,
      };
    },
    operational() {
      const changes = configurations.operational;
      const previousChanges = changes.fleets;
      const updatedChanges = externalChanges.fleets;
      return {
        fleets: externalChanges.hasOwnProperty("fleets")
          ? updatedChanges
          : previousChanges,
        logistic: changes.logistic,
        exemptionFromRegistration: changes.exemptionFromRegistration,
        hasExclusivity: changes.hasExclusivity,
        exclusivityCompanies: changes.exclusivityCompanies,
        hasDDR: changes.hasDDR,
        shipper: changes.shipper,
        registeredDrivers: changes.registeredDrivers,
        riskManagementCompanies: changes.riskManagementCompanies,
        oneResponsibleForStorage: changes.oneResponsibleForStorage,
        transportDocuments: changes.transportDocuments,
        riskManagement: changes.riskManagement,
        trackings: changes.trackings,
        employeeNumber: changes.employeeNumber,
        systemName: changes.systemName,
        fleetComposition: changes.fleetComposition,
        bodyWorkTypes: changes.bodyWorkTypes,
      };
    },

    quotationData() {
      const changes = configurations.quotationData;
      return {
        chosenInsurer: changes.chosenInsurer,
      };
    },
  };

  return {
    initial: saveFormatters.initial(),
    insured: saveFormatters.insured(),
    goods: saveFormatters.goods(),
    general: saveFormatters.general(),
    generalV2: saveFormatters.generalV2(),
    routes: saveFormatters.routes(),
    coverage: saveFormatters.coverage(),
    historic: saveFormatters.historic(),
    operational: saveFormatters.operational(),
    quotationData: saveFormatters.quotationData(),
  };
}

const generateUrlByTab = (selectedTab, code, url) => {
  switch (selectedTab) {
    case "quotations":
      return `${url + code}/quotation-data`;
    case "quotationData":
      return `${url + code}/quotation-data`;
    default:
      return url + code;
  }
};

const generatePayloadByTab = (
  selectedTab,
  data,
  configurations,
  externalChanges,
  format
) => {
  switch (selectedTab) {
    case "quotations":
      return format(data, configurations, externalChanges)[selectedTab];
    case "quotationData":
      return format(data, configurations, externalChanges)[selectedTab];
    default:
      return {
        [selectedTab]: format(data, configurations, externalChanges)[
          selectedTab
        ],
      };
  }
};

const updateConfig = {
  CARGO_SINGLE_BOARDING: {
    format: formatSingleBoardingConfig,
    url: "/cargo/v1/single-boarding/",
  },
  CARGO_OPEN_POLICY: {
    format: formatOpenPolicyConfig,
    url: "/cargo/v1/open-policy/",
  },
  SURETY: {
    format: formatSuretyConfig,
    url: "/surety/v2/",
  },
  LIFE: {
    format: formatLifeConfig,
    url: "/life/v1/",
  },
};

export function prepareOrderSave(
  data,
  configurations,
  externalChanges,
  selectedTab
) {
  const { type, code } = data;
  const { format, url } = updateConfig[type];

  const getPayloadObject = () => {
    if (type === "CARGO_OPEN_POLICY" && selectedTab === "general") {
      return {
        data: {
          ...generatePayloadByTab(
            "generalV2",
            data,
            configurations,
            externalChanges,
            format
          ).generalV2,
          documentNumber:
            configurations.initial && configurations.initial.documentNumber,
        },
        url: `/cargo/v2/open-policy/${code}/general-data`,
      };
    }
    if (type === "SURETY" && selectedTab === "term") {
      return {
        data: {
          ...generatePayloadByTab(
            "term",
            data,
            configurations,
            externalChanges,
            format
          ).term,
          documentNumber: configurations.initial?.documentNumber,
        },
        url: `/surety/v3/${code}/term-data`,
      };
    }
    return {
      data: generatePayloadByTab(
        selectedTab,
        data,
        configurations,
        externalChanges,
        format
      ),
      url: generateUrlByTab(selectedTab, code, url),
    };
  };

  return getPayloadObject();

  // return type === "CARGO_OPEN_POLICY" && selectedTab === "general"
  //   ? {
  //       data: {
  //         ...generatePayloadByTab(
  //           "generalV2",
  //           data,
  //           configurations,
  //           externalChanges,
  //           format
  //         ).generalV2,
  //         documentNumber: configurations.initial && configurations.initial.documentNumber,
  //       },
  //       url: `/cargo/v2/open-policy/${code}/general-data`,
  //     }
  //   : {
  //       data: generatePayloadByTab(
  //         selectedTab,
  //         data,
  //         configurations,
  //         externalChanges,
  //         format
  //       ),
  //       url: generateUrlByTab(selectedTab, code, url),
  //     };
}

export function getSearchValue(entity, configurations) {
  return formatMaskInput(configurations[entity].documentNumber);
}
