import React from "react";
//Components
import { Col, Row, Radio } from "antd";
//Custom Components
import SimulationQuotes from "./SimulationQuotes";
import { SuretyQuotationTable } from "components";
//helper
import { sortSimulations } from "helpers";
//Translations
import Translation from "./translation";

const Quotes = ({ simulations, quotations, handleChange }) => {
  const getSortedQuotations = (data) => {
    const base = sortSimulations(data);
    return base;
  };

  const filteredQuotations = quotations?.filter(
    (item) => item.insurer !== "BMG_SEGUROS"
  );

  const handleQuotationChanges = ({ target }) => {
    const insurer = target.value;

    if (quotations) {
      let changedQuotations = [...quotations];
      changedQuotations = changedQuotations.map((item) => {
        let quote = { ...item };
        quote.accepted = false;
        if (quote.insurer === insurer) {
          quote.accepted = true;
        }
        return quote;
      });
      handleChange({ attr: null, value: changedQuotations });
    }
  };

  const getAcceptedInsurer = (data) => {
    let selected;
    if (data) {
      data.map((item) => {
        if (item.accepted) {
          selected = item.insurer;
        }
      });
    }
    return selected;
  };

  return (
    <section className="gx-text-center">
      <Row type="flex" justify="center" className="gx-mb-5">
        <Col>
          <p className="gx-mb-3">{Translation.simulationScenario}</p>
          <SimulationQuotes data={simulations} />
        </Col>
      </Row>
      <Row type="flex" justify="center" className="gx-mt-3">
        <Col sm={24} md={20}>
          <p className="gx-mb-3">{Translation.quotationScenario}</p>
          <SuretyQuotationTable
            defaultValue={getAcceptedInsurer(filteredQuotations)}
            items={getSortedQuotations(filteredQuotations)}
            onChange={handleQuotationChanges}
          />
          <Row gutter={12} className="gx-text-left gx-mb-3">
            <Col sm={24} md={24}>
              <div>* {Translation.caption}</div>
            </Col>
          </Row>
          <Row gutter={12} className="gx-text-left gx-mb-3">
            <Col sm={24} md={24}>
              <Radio
                checked={!getAcceptedInsurer(filteredQuotations)}
                onChange={handleQuotationChanges}
              >
                {Translation.noAcceptedProposals}
              </Radio>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default Quotes;
